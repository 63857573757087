import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  Modal,
  TextField,
  Checkbox,
  Chip,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import InputMask from 'react-input-mask';

// Customizable Area Start
import { styles, TypographyView } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import { getTranslationConfig } from "../../../components/src/helpers";
import {mastercardPNG, visaPNG } from "./assets";
import EditIcon from "@material-ui/icons/Edit";

// Customizable Area End

import SubscriptionbillingController, {
  Props,
  configJSON,
} from "./SubscriptionbillingController";
import { imgBankCard, imgCheckbox, imgCheckboxChecked, imgDiscoverLogo, imgMasterLogo, imgVisaLogo } from './assets';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Formik } from 'formik';
import * as Yup from "yup";

export default class Subscriptionbilling extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { title, buttonText } = this.getModalWindowDetails();
    const { t, dir } = getTranslationConfig();

    const latestCards = this.state.latestCards

    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader context={this} forceUpdate={this.forceUpdate.bind(this)} showProfileIcon={false}/>
          <Box className='search-location-block' py={6} px='140px' dir={dir}>
            <ArrowBackIcon
              onClick={this.props.navigation.goBack}
              style={{
                color: '#0F172A',
                cursor: 'pointer'
              }}
            />
            {/* Description */}
            <Box>
              <Typography
                style={{
                  color: '#0F172A',
                  fontFamily: 'Ubuntu',
                  fontSize: '48px',
                  fontWeight: 700,
                  padding: '30px 0'
                }}
              >{t['subscription-billing-payment-title']}</Typography>
              <Typography>
                {t['subscription-billing-payment-description']}
              </Typography>
            </Box>
            {/* Payment */}
            {this.getLogicalValue(this.state.cardDetails.data.length > 0, (
              <Box className="box1">
                <Typography
                  style={{
                    color: '#334155',
                    fontFamily: 'Ubuntu',
                    fontSize: '24px',
                    fontWeight: 700,
                    padding: '32px 0 48px'
                  }}
                >Added Card</Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.cardDetails.data.map((card:any, index:any) => (
                    card && (
                      <Box
                        key={index}
                        data-test-id="select-card"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "16px",
                          border: this.state.selectCardPayment !== "" ? "3px solid #398378" : "1px solid #398378",
                          borderRadius: "16px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                          backgroundColor: "#FFFFFF",
                          width: "300px",
                          height: "120px",
                          marginRight: "30px",
                        }}
                        onClick={() => this.handleSelectCard(card.id)}
                      >
                        <Box sx={{ display: "flex" }}>
                          <img
                            src={this.getTernaryValue(card.card.brand === 'mastercard', mastercardPNG, visaPNG)}
                            alt={card.brand}
                            style={{ width: "100px", borderRadius: "4px", height: "60px" }}
                          />
                          <Box sx={{ marginLeft: "15px" }}>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "#334155", fontWeight: 700, fontSize:"17px" }}
                            >
                              {card.billing_details.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ color: "#334155", fontWeight: 700, fontSize:"17px" }}
                            >
                              **** {card.card.last4}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" height="120px">
                          <Chip
                            label="Primary"
                            size="small"
                            style={{
                              backgroundColor: "#EAF8F2",
                              color: "#398378",
                              fontWeight: 500,
                              fontSize: "12px",
                            }}
                          />
                          <Button
                            variant="text"
                            data-test-id='setPaymentModal-btn1'
                            endIcon={<EditIcon />}
                            style={{
                              fontWeight: 700,
                              fontSize: "17px",
                              color: "#009688",
                              textTransform: "capitalize",
                            }}
                            onClick={() => this.setPaymentModal(true, true, card.id)}
                          >
                            Edit
                          </Button>
                        </Box>
                      </Box>
                    )
                  ))}
                </Box>
                <Box display="flex" flexDirection="column" width="200px">
                  <Button
                    data-test-id='booking-form-btn1'
                    onClick={() => this.setPaymentModal(true)}
                    style={{
                      marginTop: "20px"
                    }}
                  >
                    <AddCircleOutlineIcon
                      className="AddIconStyle" style={{
                        color: '#398378',
                        maxHeight: '22px'
                      }} />
                    <Typography style={{
                      marginLeft: '4px',
                      fontWeight: 700,
                      color: '#398378',
                      fontSize: "16px",
                    }}>
                      Add new Card
                    </Typography>
                  </Button>
                  <Button
                    data-test-id='save-card-btn'
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSavedCardPayment()}
                    style={{
                      padding: '16px',
                      borderRadius: '8px',
                      margin: '48px 0',
                      background: '#398378',
                      color: '#FFFFFF'
                    }}
                  >
                    <Typography style={{
                      marginLeft: '4px',
                      fontWeight: 700
                    }}>
                      Continue
                    </Typography>
                  </Button>
                </Box>
              </Box>
            ), (
              <Box className="box2">
                <Box >
                  <Typography
                    style={{
                      color: '#334155',
                      fontFamily: 'Ubuntu',
                      fontSize: '24px',
                      fontWeight: 700,
                      padding: '32px 0 48px'
                    }}
                  >{t['subscription-billing-payment-method']}</Typography>
                  <Box display='flex' gridGap={32} height={140} width="300px">
                    <Box py={4} style={{ ...(styles.paymentItem as React.CSSProperties), width: 'auto', display: 'block' }} >
                      <Box
                        width={56}
                        height={56}
                        borderRadius={8}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        style={{ backgroundColor: '#E8F6F4' }}>
                        <img src={imgBankCard} alt='bank-card' />
                      </Box>
                      <Box display='flex' pt={2} gridGap={16}>
                        <Typography
                          style={{
                            color: '#334155',
                            fontFamily: 'Ubuntu',
                            fontSize: '18px',
                            fontWeight: 700,
                          }}
                        >{t['subscription-billing-payment-credit-card']}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                <Button
                  data-test-id='booking-form-btn'
                  variant="contained"
                  color="primary"
                  onClick={() => this.setPaymentModal(true)}
                  style={{
                    padding: '16px',
                    borderRadius: '16px',
                    margin: '48px 0',
                    background: '#398378',
                    color: '#FFFFFF'
                  }} 
                >
                  <AddCircleOutlineIcon
                    className="AddIconStyle" style={{
                      color: '#FFFFFF',
                      maxHeight: '22px'
                    }} />
                  <Typography style={{
                    marginLeft: '4px',
                    fontWeight: 700
                  }}>
                    {t['subscription-billing-payment-add-card-button']}
                  </Typography>
                </Button>
              </Box>
            ))}
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
        <Modal
          data-test-id='review-modal'
          open={this.state.showPaymentModal}
          onClose={() => this.setPaymentModal(false)}
        >
          <TypographyView>
            <Formik
              data-testid ="formikform"
              initialValues={this.state.paymentFormData}
              validationSchema={Yup.object().shape({
                card: Yup.string().when([], {
                  is: () => !this.state.editMode, 
                  then: Yup.string().required(t['subscription-billing-payment-card-number-placeholder']),
                  otherwise: Yup.string(),
                }),
                name: Yup.string().required(t['subscription-billing-payment-card-holder-name-placeholder']),
                cvv: Yup.number()
                  .required(t['subscription-billing-payment-cvv-placeholder'])
                  .typeError(t['subscription-billing-payment-cvv-error-numbers-only'])
                  .test('len', t['subscription-billing-payment-cvv-error-length'], value => String(value).length === 3),
                expiry: Yup.string()
                  .required(t['subscription-billing-payment-expiration-date-placeholder'])
                  .test('date', t['subscription-billing-payment-expiration-date-error-invalid'], value => {
                    if (value) {
                      const [month, year] = value.split('/');
                      const shortYear = new Date().getFullYear().toString().slice(2);

                      const validMonth = month > 0 && month < 13;
                      const validYear = +year >= +shortYear && +year < +shortYear + 6
                      return validMonth && validYear
                    }
                    return true
                  })
              })}
              onSubmit={(values = this.state.paymentFormData) => {
                const { card, expiry, cvv, name } = values;

                const [expMonth, expYear] = expiry.split('/');

                const updatedValues = {
                  card,
                  expMonth,
                  expYear,
                  cvv,
                  name
                };

                this.handlePaymentMethodSubmission(updatedValues);
              }}
            >
              {({
                handleChange,
                submitForm,
                errors,
                touched,
                handleBlur,
                values
              }) => {
                const {
                  name,
                  card,
                  cvv,
                  expiry
                } = errors;
                const isCardError = this.getInputError(touched.name, name);
                const isNameError = this.getInputError(touched.card, card);
                const isCVVError = this.getInputError(touched.cvv, cvv);
                const isExpiryError = this.getInputError(touched.expiry, expiry);
                return (
                  <Box
                    data-test-id='review-form'
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '55%',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px 8px 32px 8px',
                      outline: 'none'
                    }}
                  >
                    <Box p={3} pl={5} borderBottom='1px solid #E2E8F0' display='flex' justifyContent='space-between' alignItems='center'>
                      <Typography
                        style={{
                          fontSize: '24px',
                          fontWeight: 'bold'
                        }}
                      >
                        {this.state.editMode ? "Edit Card Details" : "Add Card Details"}
                      </Typography>
                      <CloseIcon
                        data-test-id='review-close-btn'
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setPaymentModal(false)}
                      />
                    </Box>
                    <Box px={5} py={2} borderBottom='1px solid #E2E8F0' display='flex' justifyContent='space-between' flexWrap='wrap'>

                      <Box style={styles.inputBlock}>
                        <Box sx={styles.inputTitle}>
                          <InputLabel className="test-label">{t['subscription-billing-payment-card-number']}</InputLabel>
                          <InputLabel className="test-star">*</InputLabel>
                        </Box>
                        <InputMask
                          data-test-id='date-input'
                          mask="9999 9999 9999 9999"
                          value={values.card}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={this.state.editMode}
                          maskChar=" "
                        >
                          {() => <TextField
                            name='card'
                            type="text"
                            fullWidth={true}
                            value={values.card}
                            variant="outlined"
                            className="inputeStyle"
                            placeholder={t['subscription-billing-payment-card-number-label']}
                            error={this.state.editMode ? false : isCardError}
                          />}
                        </InputMask>
                        <Typography className="txtErrorMsg">{touched.card && card}</Typography>
                      </Box>
                      <Box style={styles.inputBlock}>
                        <Box sx={styles.inputTitle}>
                          <InputLabel className="test-label">{t['subscription-billing-payment-card-holder-name']}</InputLabel>
                          <InputLabel className="test-star">{` `}*</InputLabel>
                        </Box>
                        <TextField
                          name='name'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          variant="outlined"
                          className="inputeStyle"
                          data-test-id="customer-name"
                          placeholder={t['subscription-billing-payment-card-holder-name-label']}
                          error={isNameError}
                        />
                        <Typography className="txtErrorMsg">{touched.name && name}</Typography>
                      </Box>
                      <Box style={styles.inputBlock}>
                        <Box sx={styles.inputTitle}>
                          <InputLabel className="test-label">{t['subscription-billing-payment-cvv']}</InputLabel>
                          <InputLabel className="test-star">{` `}*</InputLabel>
                        </Box>
                        <TextField
                          data-test-id='cvv-input'
                          name='cvv'
                          type='password'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cvv}
                          variant="outlined"
                          className="inputeStyle"
                          placeholder={t['subscription-billing-payment-cvv-label']}
                          error={isCVVError}
                        />
                        <Typography className="txtErrorMsg">{touched.cvv && cvv}</Typography>
                        {!this.state.paymentModalUpdateMode && <Box
                          className="remember"
                        >
                          <Checkbox
                            name='saveCard'
                            onChange={handleChange}
                            value={values.saveCard}
                            data-test-id='checkbox-save-item'
                            icon={<img src={imgCheckbox} alt='checkbox' />}
                            checkedIcon={<img src={imgCheckboxChecked} alt='checkbox' />}
                          />
                          <span style={{ fontSize: '14px', color: '#64748B' }}>{t['subscription-billing-payment-save-card-details']}</span>
                        </Box>}
                      </Box>
                      <Box style={styles.inputBlock}>
                        <Box sx={styles.inputTitle}>
                          <InputLabel className="test-label">{t['subscription-billing-payment-expiration-date']}</InputLabel>
                          <InputLabel className="test-star">{` `}*</InputLabel>
                        </Box>

                        <InputMask
                          data-test-id='expiry-date-input'
                          mask="99/99"
                          value={values.expiry}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={false}
                          maskChar=" "
                        >
                          {() => <TextField
                            name='expiry'
                            fullWidth={true}
                            variant="outlined"
                            className="inputeStyle"
                            placeholder={t['subscription-billing-payment-expiration-date-label']}
                            error={isExpiryError}
                          />}
                        </InputMask>
                        <Typography className="txtErrorMsg">{touched.expiry && expiry}</Typography>
                      </Box>
                    </Box>

                    <Box p={3}>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        width='50%'
                        marginLeft='auto'>
                        <Button
                          data-test-id='cancel-btn'
                          variant="contained"
                          color="primary"
                          onClick={() => this.setPaymentModal(false)}
                          className="declineButton"
                        >
                          {this.state.editMode ? "Delete Card" : t['landing-page-venues-book-cancel']}
                        </Button>
                        <Button
                          data-test-id='submit-btn'
                          type='submit'
                          variant="contained"
                          color="primary"
                          className="agreeButton"
                          onClick={submitForm}
                        >
                          {this.getTernaryValue(
                            this.state.editMode,
                            "Save Details",
                            values.saveCard ? t['subscription-billing-payment-save-and-pay-button'] : t['subscription-billing-payment-pay-card-button']
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )
              }}

            </Formik>
          </TypographyView>
        </Modal >
      </TypographyView >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
