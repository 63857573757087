import React from 'react';
// Customizable Area Start

import { Box, Button, Snackbar, Typography, styled } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import dayjs from 'dayjs';
// Customizable Area End

import Ordermanagement1Controller, {
  BookingStatusEnum,
  Props,
  Status
} from './Ordermanagement1Controller';
import { TypographyView, styles } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import {
  imgLocationSearch,
  imgPendingClockGreen,
  call,
  message,
  customerImg,
} from './assets';
import { findCurrByCode, getStars, getTranslationConfig } from '../../../components/src/helpers';
import BookingModal from './components/BookingModal';
import CancelModal from './components/CancelModal';
import CustomerCancelModal from './components/CustomerCancelModal';
import Loader from "../../../components/src/Loader.web";
import BookingConfirmationModal from './components/BookingConfirmationModal';
import LoginModal from './components/LoginModal';
import { PlanExpiryPrompt } from '../../../components/src/PlanExpiryPrompt';
import BookingCouponModal from './components/BookingCouponModal';

type BookingTypes = 'unavailable' | 'spa' | 'customer'

const customStyles = {
  vertCenter: {
    display: 'flex',
    alignItems: 'center',
    gridGap: 12
  }
};

export default class Ordermanagement1 extends Ordermanagement1Controller {
  catalogueRef: any;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.setDatesRange = this.setDatesRange.bind(this);
    this.getCatalogues();

    this.catalogueRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'My Profile': 'SpaUserProfile',
    'My Bookings': 'MyBookings',
  };

  ownerNavigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
    'My Booking': 'SpaBooking',
  }

  cancelledByFromSPA = {
    spa: 'you',
    customer: 'customer'
  }

  cancelledByFromCustomer = {
    spa: 'SPA',
    customer: 'you'
  }
  // Customizable Area End

  render() {
    const { t, dir, locale } = getTranslationConfig();
    const { isCustomer, bookingDetails, discountedPrice, isSpa, customerDetails, discount_type } = this.state;

    const userDetails = {
      name: t['booking-name'],
      phone_number: t['booking-phone-number'],
      email: t['booking-email'],
      gender: t['booking-gender'],
      total_price: t['booking-total-amount'],
    }

    const {
      id = undefined,
      additional_notes = "",
      personal_data: {
        address = "",
        name = "",
        service_name = '',
        phone_number = '',
        email = '',
        spa_image = "",
        customer_detail: {
          gender = "",
          email: customerEmail = "",
          phone_number: customerPhoneNumber = ""
        } = {},
        category = "",
        sub_category_name = "",
        region = "",
        service_image = [],
        spa_detail: {
          full_name = "",
        } = {},
        appliedcoupon = {},
        slot: { id: slotId = 0, catalogue_id = null } = {},
      } = {},
      booking_date = '',
      start_time = '',
      end_time = '',
      status = '',
      cancelled_by = null,
      total_price = 0,
      booking_by = 'unavailable'
    } = this.getEmptyObject(bookingDetails as any);

    this.getBookingDetailsByRef.bind(this)(catalogue_id);

    const rating = Number(customerDetails?.attributes.average_review);

    const bookingMode = {
      customer: t['booking-type-online'],
      spa: t['booking-type-offline'],
      null: t['booking-type-not-available'],
      unavailable: t['booking-type-not-available'],
    }[booking_by?.toLowerCase() as BookingTypes];

    const bookingId = this.state.bookingId

    const previewData = this.state.storedBookingInfo
    const { isPreviewMode } = this.state
    const timeSlots = this.state.selectedTimeSlotPrice

    const dateToFormat = this.getTernaryValue(isPreviewMode, timeSlots?.date, booking_date);
    const formattedDate = this.formatDate(this.removeTimeZoneOffset(dateToFormat), locale);
    const { formattedStartTime, formattedEndTime } = this.formatTimesBasedOnMode(
      timeSlots?.start_time,
      timeSlots?.end_time,
      start_time,
      end_time,
      isPreviewMode,
      locale
    );
    const combinedAddress = this.getCombinedAddress(previewData?.complete_attributes);
    const bookingPrice = this.getTernaryValue(isPreviewMode, timeSlots?.price, total_price)
    const finalPrice = this.calculateFinalPrice(bookingPrice, discountedPrice, discount_type);

    const availabilities = this.getAvailabilities();

    const durationSlots = availabilities?.map((avail) => {
      const {
        duration,
        dates,
        timeslots
      } = avail;
      return ({
        id: duration.id,
        duration: duration.duration,
        price: duration.price,
        dates,
        timeslots
      })
    });

    const resCurrency = findCurrByCode(String(previewData?.currency)) || this.currency;

    const choosenDur = durationSlots?.find((durSlot) => durSlot.id === slotId);
    const showCancelledBy = cancelled_by;

    const appliedCouponExists = appliedcoupon && Object.keys(appliedcoupon).length;
    const discountType = appliedcoupon?.discount_type;
    const discountAmount = appliedcoupon?.discount;

    return (
      // Customizable Area Start
      <>
        {this.state.loading ?
          <Loader loading /> :
          <TypographyView>
            <Box style={styles.SafeAreaView} className='landing-venue-block'>
              <AppHeader
                context={this}
                forceUpdate={this.forceUpdate.bind(this)}
              />
              {/* Main content part */}
              <Box
                dir={dir}
                className='search-location-block'
                py={6}
                px='140px'
              >
                <BreadCrumbs
                  data-testid="order-management-breadcrumbs"
                  items={this.getTernaryValue(
                    isSpa,
                    [
                      t['breadcrumbs-home'],
                      t['breadcrumbs-spa-profile'],
                      t['breadcrumbs-my-bookings'],
                      t[`booking-${status}`]
                    ],
                    [
                      t['breadcrumbs-home'],
                      t['breadcrumbs-my-profile'],
                      t['breadcrumbs-my-bookings'],
                      t['breadcrumbs-view-details']
                    ]
                  )}
                  targetColor='#398378'
                  navigationMap={this.getTernaryValue(isSpa, this.ownerNavigationMap, this.navigationMap)}
                  navigate={this.handleNavigate}
                />
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography
                    style={{
                      color: '#0F172A',
                      fontFamily: 'Ubuntu',
                      fontSize: '48px',
                      fontWeight: 700,
                      padding: '30px 0',
                    }}
                  >
                    {t['booking-details-title']}
                  </Typography>
                  {isSpa && status === "booked" && <Button
                    data-test-id='comp-booking-btn'
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleUserAction() && this.completeBooking({ id })}
                    className="regButton"
                    style={{
                      background: 'var(--Primary, #D9EEEB)',
                      color: '#047854'
                    }}
                  >
                    <Typography style={{
                      marginLeft: '4px',
                      fontWeight: 700
                    }}>
                      {t['booking-mark-completed']}
                    </Typography>
                  </Button>}
                </Box>
                <Box mb={3}>
                  <Typography
                    style={{
                      color: '#0F172A',
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: 700,
                      paddingBottom: '4px',
                    }}
                    data-testid="customer-full-name"
                  >
                    {this.getTernaryValue(isPreviewMode, previewData?.full_name, full_name)}
                  </Typography>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography
                      style={{
                        color: '#334155',
                        fontFamily: 'Ubuntu',
                        fontSize: '20px',
                      }}
                    >
                      {t['booking-id-name']} - {"BK  " + this.getTernaryValue(isPreviewMode, this.state.preview, id)}
                    </Typography>
                    {showCancelledBy && isSpa && <Typography
                      style={{
                        color: '#334155',
                        fontFamily: 'Ubuntu',
                        fontSize: '20px',
                      }}
                    >
                      {`${t['booking-cancelled-by']} ${this.cancelledByFromSPA[cancelled_by.toLowerCase() as never]}`}
                    </Typography>}
                  </Box>
                  {isSpa && <Typography
                    style={{
                      color: '#334155',
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      marginTop: '8px'
                    }}
                  >
                    {t['booking-type']} - {bookingMode}
                  </Typography>}
                </Box>
                <Box
                  p='12px'
                  width='35%'
                  borderRadius='30px'
                  bgcolor='#ffffff'
                  whiteSpace='nowrap'
                  mb='14px'
                  {...customStyles.vertCenter}
                >
                  <Box
                    p={1}
                    borderRadius='50%'
                    bgcolor='#FEF3C7'
                    display='inline-block'
                    mr='14px'
                  >
                    <img
                      src={imgLocationSearch}
                      alt='map marker'
                      style={{ display: 'block' }}
                    />
                  </Box>
                  <Typography
                    style={{
                      ...styles.regularMiddle,
                      ...styles.dotsOverflow,
                      display: 'inline-block',
                      width: '80%',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {this.getTernaryValue(isPreviewMode, combinedAddress, address)}
                  </Typography>
                </Box>
                {isSpa && (
                  <Box display='flex' justifyContent='space-between' mb='20px'>
                    <Box
                      p='12px'
                      width='35%'
                      borderRadius='30px'
                      bgcolor='#ffffff'
                      whiteSpace='nowrap'
                      mb='14px'
                      {...customStyles.vertCenter}
                    >
                      <Box
                        p={1}
                        borderRadius='50%'
                        bgcolor='#D1FAE5'
                        display='inline-block'
                        mr='14px'
                      >
                        <img
                          src={imgPendingClockGreen}
                          alt='map marker'
                          style={{ display: 'block' }}
                        />
                      </Box>
                      <Typography
                        style={{
                          ...styles.regularMiddle,
                          ...styles.dotsOverflow,
                          display: 'inline-block',
                          width: '80%',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {this.formatDate(this.removeTimeZoneOffset(dateToFormat), locale)}, {' '}
                        <span style={{ fontWeight: "bold" }}>
                          {this.formatTime(start_time, locale)}-
                          {this.formatTime(end_time, locale)}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                )}
                {isSpa ? (
                  <Box display='flex' gridGap={32} mb={4}>
                    {/* Venue card SPA owner */}
                    <Box
                      display='flex'
                      gridGap={32}
                      flexBasis="60%"
                      p={2}
                      borderRadius='4px'
                      borderLeft='2px solid #398378'
                      style={{
                        backgroundColor: '#FFFFFF',
                      }}
                    >
                      <Box
                        style={{
                          position: 'relative',
                          width: '40%',
                          height: '100%',
                          borderRadius: '8px',
                          background: `url(${service_image}) center/cover`,
                        }}
                      >
                        {status === "booked" && <Box
                          display='flex'
                          gridGap={16}
                          position='absolute'
                          flexWrap='wrap'
                          bottom={24}
                          left={16}
                        >
                          <Button
                            data-test-id='resc-booking-btn'
                            style={{
                              padding: '10px',
                              borderRadius: 8,
                              backgroundColor: '#e8f6f4ff',
                              color: '#398378ff',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: 700,
                              textTransform: 'none',
                            }}
                            onClick={() => this.setOpenRescheduleModal(true)}
                          >
                            {t['booking-reschedule-btn']}
                          </Button>
                          <Button
                            data-test-id='cancel-booking-btn'
                            style={{
                              padding: '10px',
                              borderRadius: 8,
                              backgroundColor: '#e8f6f4ff',
                              color: '#398378ff',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: 700,
                              textTransform: 'none',
                            }}
                            onClick={() => this.setOpenCancelModal.bind(this)(id)}
                          >
                            {t['booking-cancel-btn']}
                          </Button>
                        </Box>}
                      </Box>

                      <Box>
                        <Typography
                          style={{
                            fontSize: '20px',
                            color: '#000000',
                            fontWeight: 700,
                            padding: 0,
                          }}
                        >
                          {service_name}
                        </Typography>
                        <BreadCrumbs
                          items={[category, sub_category_name, region]}
                          fontSize='12px'
                          color='#1E293B'
                        />
                        {/* stars */}
                        <Box display='flex' p={1} py={2}>
                          <Typography
                            style={{
                              fontSize: '20px',
                              fontWeight: 700,
                            }}
                          >
                            {this.getTernaryValue(Boolean(rating), rating.toFixed(1), "0.0")}
                          </Typography>
                          <Box display='flex' marginLeft='4px'>
                            {getStars(rating)}
                          </Box>
                        </Box>
                        <Typography
                          style={{
                            fontSize: '14px',
                            color: '#000000',
                            fontWeight: 700,
                            marginBottom: '16px',
                          }}
                        >
                          {t['booking-additional-notes']}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '14px',
                            color: '#000000',
                          }}
                        >
                          {additional_notes}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Customer card */}
                    <Box
                      p={2}
                      flexBasis="40%"
                      style={{
                        backgroundColor: '#FFFFFF',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#000000',
                          fontWeight: 700,
                          marginBottom: '16px',
                        }}
                      >
                        {t['booking-customer-info']}
                      </Typography>
                      <Box>
                        {Object.values(userDetails).map(
                          (property, index, array) => {
                            const userInfo = [name, customerPhoneNumber, customerEmail, gender, `${this.currency} ${Number(total_price).toFixed(2)}`]
                            const value = userInfo[index];
                            return (
                              <Box
                                key={property}
                                display='flex'
                                pb='12px'
                                {...(this.getTernaryValue(index + 2 === array.length, { pb: '24px' }, {}))}
                              >
                                <Typography style={styles.customerInfoItem}>
                                  {property}
                                </Typography>
                                <Typography
                                  style={{
                                    ...styles.customerInfoItem,
                                    fontWeight: 700,
                                  }}
                                >
                                  <span style={{ fontWeight: 400 }}>{": "}</span>
                                  {value}
                                </Typography>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box display='flex' gridGap={32}>
                    {/* Venue card customer */}
                    <Box
                      display='flex'
                      gridGap={32}
                      width='65%'
                      p={2}
                      borderRadius='4px'
                      borderLeft='2px solid #398378'
                      style={{
                        backgroundColor: '#FFFFFF',
                        height: "20rem"
                      }}
                    >
                      <Box style={{ position: 'relative' }}>
                        <Box display='flex'>
                          <img
                            data-testid="customer-image"
                            src={spa_image || customerImg}
                            alt='customerImg'
                            style={{
                              marginTop: '7px',
                              borderRadius: '6px',
                              zIndex: 0,
                              position: 'relative',
                              width: '100%',
                              height: "300px"
                            }}
                          />
                        </Box>
                        <Box
                          display='flex'
                          gridGap={16}
                          position='absolute'
                          flexWrap='wrap'
                          bottom={24}
                          left={16}
                        >
                          {this.getLogicalValue(
                            this.state.buttonMode === 'booked',
                            <>
                              <ImageButton
                                data-test-id='customer-reschedule-booking-btn'
                                style={{}}
                                onClick={() => this.setOpenRescheduleModal(true)}
                              >
                                {t['booking-reschedule-btn']}
                              </ImageButton>
                              <ImageButton
                                data-test-id='booked-cancel-booking-btn'
                                style={{}}
                                onClick={() => this.setOpenCustomerCancelModal(true)}
                              >
                                {t['booking-cancel-btn']}
                              </ImageButton>
                            </>
                          )}
                          {this.getLogicalValue(
                            this.state.buttonMode === '' &&
                            !this.state.isPreviewMode,
                            (
                              <ImageButton
                                data-test-id='customer-cancel-booking-btn'
                                style={{}}
                                onClick={() => this.setOpenCustomerCancelModal(true)}
                              >
                                {t['booking-cancel-btn']}
                              </ImageButton>
                            )
                          )}
                        </Box>
                      </Box>
                      <Box mt={1}>
                        <Typography
                          style={{
                            fontSize: '20px',
                            color: '#000000',
                            fontWeight: 700,
                            padding: 0,
                          }}
                        >
                          {this.getTernaryValue(isPreviewMode, previewData?.full_name, full_name)}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '16px',
                            color: '#000000',
                            fontWeight: 400,
                            padding: 0,
                          }}
                          data-testid="subCategory"
                        >
                          {this.getTernaryValue(isPreviewMode, timeSlots?.subcategoryName.name, service_name)}
                        </Typography>
                        {/* stars */}
                        <Box
                          display='flex'
                          alignItems='center'
                          py={1}
                          marginTop={1}
                        >
                          <Box display='flex'>{getStars(this.getTernaryValue(!!rating, this.getDevidedResultValue(rating, 0), 5))}</Box>                      <Typography
                            style={{
                              fontSize: '10px',
                              lineHeight: '22px',
                              fontWeight: 400,
                              fontFamily: 'Ubuntu',
                              color: '#64748B',
                            }}
                          >
                            {this.getTernaryValue(!!rating, rating, 0)}/5
                          </Typography>
                        </Box>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          mb='10px'
                          borderRadius='29px'
                        >
                          <Box
                            py={1}
                            width='100%'
                            borderRadius='30px'
                            bgcolor='#F8FAFC'
                            whiteSpace='nowrap'
                            my={3}
                            {...customStyles.vertCenter}
                          >
                            <Box
                              p={1}
                              borderRadius='50%'
                              bgcolor='#D1FAE5'
                              display='inline-block'
                              mr='10px'
                            >
                              <img
                                src={imgPendingClockGreen}
                                style={{ display: 'block' }}
                                alt='map marker'
                              />
                            </Box>
                            <Typography
                              style={{
                                ...styles.regularMiddle,
                                ...styles.dotsOverflow,
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                fontSize: '14px',
                                fontWeight: 400,
                              }}
                            >
                              {this.getResultValue(dateToFormat, this.isValidDate(dateToFormat), (
                                <>
                                  {formattedDate}, {' '}
                                </>
                              ))}
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  paddingRight: '10px',
                                }}
                              >
                                {formattedStartTime} {' - '} {formattedEndTime}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          style={{
                            fontSize: '14px',
                            color: '#000000',
                            fontWeight: 700,
                            marginBottom: '16px',
                          }}
                        >
                          {t['booking-contact-details']}
                        </Typography>
                        <Box display='flex' mb='10px'>
                          <Box mr='20px'>
                            <img src={call} alt='call' />
                          </Box>
                          <Typography
                            style={{
                              fontSize: '14px',
                              color: '#000000',
                            }}
                          >
                            {this.getTernaryValue(isPreviewMode, previewData?.phone_number, phone_number)}
                          </Typography>
                        </Box>
                        <Box
                          display='flex'
                          mb='10px'
                          gridGap={8}
                        >
                          <Box mr='20px'>
                            <img src={message} alt='call' />
                          </Box>
                          <Typography
                            style={{
                              fontSize: '14px',
                              color: '#000000',
                            }}
                          >
                            {this.getTernaryValue(isPreviewMode, previewData?.email, email)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* Customer card */}
                    <Box
                      p={2}
                      width='30%'
                      style={{
                        backgroundColor: '#FFFFFF',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#000000',
                          fontWeight: 700,
                          marginBottom: '14px',
                        }}
                      >
                        {t['booking-overview']}
                      </Typography>
                      {showCancelledBy && isCustomer && <Typography
                        style={{
                          color: '#000000',
                          fontWeight: 400,
                          marginBottom: '14px',
                        }}
                      >
                        {`${t['booking-cancelled-by']} ${this.cancelledByFromCustomer[cancelled_by.toLowerCase() as never]}`}
                      </Typography>}
                      <Typography
                        style={{
                          color: '#000000',
                          fontWeight: 400,
                          marginBottom: '14px',
                        }}
                        data-testid="subCategorys"
                      >
                        {this.getTernaryValue(isPreviewMode, timeSlots?.subcategoryName.name, service_name)}
                      </Typography>

                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Box
                          display='flex'
                          alignItems='center'
                          bgcolor='#E8F6F4'
                          p={1}
                          borderRadius='10px'
                        >
                          <span
                            style={{
                              color: '#398378',
                              fontSize: '14px',
                              fontWeight: 'bold',
                            }}
                          >
                            {formattedStartTime} {' - '} {formattedEndTime}
                          </span>
                        </Box>
                        <Box display='flex' alignItems='center'>
                        </Box>
                      </Box>

                      <hr
                        style={{
                          color: '#E2E8F0',
                          marginTop: '15px',
                        }}
                      />
                      <>
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                          <Typography
                            style={{
                              color: '#0F172A',
                              fontWeight: 400,
                              marginTop: '14px',
                            }}
                          >
                            {t['booking-total']}
                          </Typography>
                          <Typography
                            style={{
                              color: '#0F172A',
                              fontWeight: 400,
                              marginTop: '14px',
                            }}
                            data-testid="price"
                          >
                            {resCurrency}
                            {this.getTernaryValue(
                              discountType,
                              this.calculateBookingPriceWithDiscount(bookingPrice, appliedcoupon),
                              this.getTernaryValue(isPreviewMode, timeSlots?.price, bookingPrice)
                            )}
                          </Typography>
                        </Box>
                        {this.getLogicalValue(
                          this.getDevidedResultValue(
                            discountedPrice !== null,
                            appliedCouponExists
                          ),
                          <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <Typography
                              style={{
                                color: 'black',
                                fontWeight: 400,
                                marginTop: '14px',
                                cursor: 'pointer',
                              }}
                            >
                              {t['booking-coupon-applied']}
                            </Typography>
                            <Box>
                              <Typography
                                style={{
                                  color: '#0F172A',
                                  fontWeight: 400,
                                  marginTop: '14px',
                                }}
                              >
                                {this.getTernaryValue(
                                  appliedCouponExists,
                                  this.getTernaryValue(
                                    discountType === 'percentage',
                                    `${discountAmount}%`,
                                    `${this.currency}${discountAmount}`
                                  ),
                                  this.getTernaryValue(
                                    discount_type === 'percentage',
                                    `${discountedPrice}%`,
                                    `${this.currency}${discountedPrice}`
                                  )
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {this.getLogicalValue(
                          this.getDevidedResultValue(discountedPrice as any, ['booked', 'completed', 'cancelled'].includes(this.state.buttonMode)),
                          <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <Typography
                              style={{
                                color: '#0F172A',
                                fontWeight: 700,
                                marginTop: '14px',
                                cursor: 'pointer',
                              }}
                            >
                              {t['booking-new-total']}
                            </Typography>
                            <Box>
                              <Typography
                                style={{
                                  color: '#0F172A',
                                  fontWeight: 700,
                                  marginTop: '14px',
                                }}
                              >
                                {resCurrency} {this.getTernaryValue(discountedPrice !== null, finalPrice, bookingPrice)}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                      </>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='space-between'
                      >
                        {this.getLogicalValue(
                          this.isNotFamStatus(),
                          <>
                            {this.getTernaryValue(
                              discountedPrice !== null,
                              (
                                <StyledButton
                                  style={{ marginTop: '2rem' }}
                                  onClick={() => {
                                    const id = this.getTernaryValue(isPreviewMode, 'preview', bookingId)
                                    this.goToCouponsPage(id);
                                  }}
                                  data-test-id='cpn-button'
                                  endIcon={<ArrowForwardIosIcon style={{ fontSize: '14px' }} />}
                                >
                                  {t['booking-discount-by']} {discountedPrice} %
                                </StyledButton>
                              ),
                              (
                                <StyledButton
                                  style={{ marginTop: '2rem' }}
                                  onClick={() => {
                                    const id = this.getTernaryValue(isPreviewMode, 'preview', bookingId);
                                    this.goToCouponsPage(id);
                                  }}
                                  data-test-id='cpn-button'
                                  endIcon={<ArrowForwardIosIcon style={{ fontSize: '14px' }} />}
                                >
                                  {t['booking-use-coupons']}
                                </StyledButton>
                              )
                            )}
                            <StyledButton
                              data-test-id='book-button'
                              onClick={() => this.handleButtonClick()}
                            >
                              {t['booking-book']}
                            </StyledButton>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <BookingModal
                openRescheduleModal={this.state.openRescheduleModal}
                setOpenRescheduleModal={this.setOpenRescheduleModal}
                navigation={this.props.navigation}
                id={`${id}`}
                userName={this.state.modalName}
                modalContent={{
                  title: t['booking-reschedule-title'],
                  buttonTitle: t['booking-reschedule-title'],
                  type:"reschedule"
                }}
                durationSlots={durationSlots}
                modalData={{
                  name,
                  serviceName: service_name,
                  duration: choosenDur,
                  slot:
                    this.formatTime(start_time) +
                    " - " +
                    this.formatTime(end_time),
                  date: dayjs(booking_date).format("MMMM DD, YYYY"),
                  price: total_price,
                }}
                setOpenSnack={this.setOpenSnack}
              />
              <CancelModal
                openCancelModal={this.state.openCancelModal}
                setOpenCancelModal={this.setOpenCancelModal}
                navigation={this.props.navigation}
                userName={this.selectedBookingUserName()}
                onCancelBooking={this.cancelBooking}
                bookedID={id}
                id=''
              />
              <CustomerCancelModal
                openCustomerCancelModal={this.state.openCustomerCancelModal}
                setOpenCustomerCancelModal={this.setOpenCustomerCancelModal}
                navigation={this.props.navigation}
                onCancelBooking={this.cancelBooking}
                bookedID={id}
                id=''
              />
              <BookingConfirmationModal
                navigation={this.props.navigation}
                id={''}
                showConfirmationModal={this.state.showConfirmationModal}
                setOpenBookingConfirmationModal={this.setOpenBookingConfirmationModal}
              />
              <LoginModal
                navigation={this.props.navigation}
                id={''}
                openLoginModal={this.state.openLoginModal}
                setOpenLoginModal={this.setOpenLoginModal}
              />
              <BookingCouponModal
              navigation={this.props.navigation}
              id={''}
              errorMessageCoupen={this.state.errorMessageCoupen}
              openBookingCouponModal={this.state.openBookingCouponModal}
              setBookingCouponModal={this.setBookingCouponModal}
              />
              <PlanExpiryPrompt context={this} userAction={this.state.userAction} />
              {/* footer */}
              <AppFooter navigation={this.props.navigation} />
            </Box>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.showSnack}
              onClose={() => this.setState({ showSnack: false })}
              style={{
                backgroundColor: '#FFFFFF',
                color: '#0F172A',
                borderRadius: '8px',
                padding: '8px 16px',
                boxShadow: '0px 6px 15px -3px #00000026',
              }}
              autoHideDuration={3000}
            >
              <>
                <CheckCircleIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    color: '#34D399',
                    marginRight: '8px',
                  }}
                />
                <Typography>{t['booking-reschedule-snack']}</Typography>
              </>
            </Snackbar>
          </TypographyView>}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledButton = styled(Button)({
  backgroundColor: '#D9EEEB',
  color: '#398378',
  borderRadius: '10px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 700,
  textTransform: 'none',
  marginTop: '10px',
  marginBottom: '10px',
  '&:hover': {
    backgroundColor: '#398378',
    color: 'white',
  },
});

const ImageButton = styled(Button)({
  padding: '10px',
  borderRadius: 8,
  backgroundColor: '#e8f6f4ff',
  color: '#398378ff',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  textTransform: 'none',
  zIndex: 22,
  marginBottom: '10px',
  '&:hover': {
    backgroundColor: '#D9EEEB',
  },
});
// Customizable Area End
