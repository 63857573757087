import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles, TypographyView } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import SubscriptionBlock from './components/SubscriptionBlock.web';
import { getTranslationConfig } from "../../../components/src/helpers";

// Customizable Area End

import SubscriptionbillingController, {
  Props,
  SubscriptionTime,
  SubscriptionType,
  configJSON,
  initSubscription,
} from "./SubscriptionbillingController";

export default class Subscriptionbilling extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
  }

  async componentDidMount() {
    this.getUserCurrentSubscription();
  }
  // Customizable Area End

  render() {
    const { t, dir } = getTranslationConfig();
    const {
      plan: {
        name,
        description,
        price,
        image_url: imageUrl,
        update_plan: updatePlan,
        plan_content: planContent,
        plan_type: planType,
        content_types:content_types
      },
      status,
      expiry_date: expiryDate,
     
    } = this.state.currentSubscriptionPlan || initSubscription;    

    const parsedDescrList = JSON.parse(planContent || initSubscription.plan.plan_content);
    const isExpired = status === 'Expired';

    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader context={this} forceUpdate={this.forceUpdate.bind(this)} />
          <Box className='search-location-block' py={6} px='140px' dir={dir}>
            <BreadCrumbs
              items={[t['breadcrumbs-home'], t['breadcrumbs-spa-profile'], t['subscription-my-subscription-title']]}
              targetColor='#398378'
              navigationMap={this.navigationMap}
              navigate={this.props.navigation.navigate}
            />
            <Typography
              style={{
                color: '#0F172A',
                fontFamily: 'Ubuntu',
                fontSize: '48px',
                fontWeight: 700,
                padding: '30px 0'
              }}
            >{t['subscription-my-subscription-title']}</Typography>
            <Typography>
              {t['subscription-my-subscription-description']}
            </Typography>
            {/* Plan Block */}
            <Box my={4} width='100%' style={{ backgroundColor: '#FFFFFF' }}>
              <Box py={2} px={4} mb={2} style={{ backgroundColor: '#398378' }}>
                <Typography style={{ fontSize: '30px', fontWeight: 700, color: '#FFFFFF' }}>{t['subscription-my-current-plan-title']}</Typography>
              </Box>
              {this.state.currentSubscriptionPlan ? <SubscriptionBlock
                navigation={this.props.navigation}
                id=''
                subsContent={{
                  title: name,
                  currancy: this.currency,
                  price,
                  period: planType === SubscriptionType.monthly ? SubscriptionTime.month : SubscriptionTime.year,
                  status,
                  expiryDate,
                  description,
                  parsedDescrList,
                  imageUrl,
                  updatePlan,
                  periodLabel:"",
                  content_types
                }}
                subscriptionButtons={
                  <Box display='flex' gridGap={24}>
                    {isExpired && <Button
                      data-test-id='renew-btn'
                      variant="contained"
                      color="primary"
                      style={{
                        ...styles.actionButton,
                        ...this.getRenewButtonStyle(updatePlan)
                      } as React.CSSProperties}
                      onClick={() => this.gotoSubscriptionPlans()}
                    >
                      {`${t['subscription-my-current-plan-renew-button']} ${name}`}
                    </Button>}
                    <Button
                      data-test-id='update-btn'
                      variant="contained"
                      color="primary"
                      style={{
                        ...styles.actionButton,
                        ...(!updatePlan && {
                          visibility: 'hidden'
                        }),
                        background: 'var(--Primary, #D9EEEB)',
                        color: '#398378',
                      } as React.CSSProperties}
                      onClick={() => this.gotoSubscriptionPlans()}
                    >
                      {`${t['subscription-my-current-plan-upgrade-button']} ${updatePlan}`}
                    </Button>
                  </Box>
                }
              /> :
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '38vh'
                }}>
                  <CircularProgress style={{ color: '#398378' }} />
                </Box>}
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
