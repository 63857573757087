import React, { ChangeEvent } from "react";
// Customizable Area Start
import { Box, Button, Typography, CircularProgress, TextField, InputAdornment, InputLabel, Select, MenuItem, styled, Checkbox } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { allServices, Coupon, initCouponFormValues } from "./DashboardController.web";
import { addIcon, deleteLicence, editLicense, spaSettingsCover } from '../../../blocks/user-profile-basic/src/assets';
import { BookingWrapper } from '../../../blocks/ordermanagement1/src/components/BookingWrapper';
import { noCoupons } from './assets';
import { imgI14750489301945250HTpe556x7cBoLvN2ZuRRme } from '../../../blocks/landingpage/src/assets';
import { ButtonComponent, RegistrationFormView, styles, TypographyView } from '../../../components/src/styles';
import { CustomPopover } from '../../../blocks/user-profile-basic/src/SpaUserProfile.web';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ModalWrapperComponent } from '../../../components/src/ModalWrapperComponent';
import { Autocomplete } from '@material-ui/lab';
import * as Yup from "yup";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import DatePicker from 'react-multi-date-picker';
import arabicLocale from 'react-date-object/locales/gregorian_ar';

import { calendarIcon } from '../../../blocks/settings1/src/assets';
import { PlanExpiryPrompt } from '../../../components/src/PlanExpiryPrompt';
import '../../../components/src/calendar.css';
import { getTranslationConfig } from '../../../components/src/helpers';


// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.calendarRef = React.createRef();
    this.firstFormLoadRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start
  firstFormLoadRef: any;
  calendarRef: any;

  getTranslatedArray = (keys: any[]) => {
    const { t } = getTranslationConfig();
    return keys.map((key) => t[key]);
  };


  menuComponent = (index: number, couponId: number) => {
    const { t, isArabic, dir } = getTranslationConfig();

    return (
      <Box dir={dir}>
        <MoreVertIcon
          data-test-id="menu-icon"
          style={{ cursor: 'pointer' }}
          onClick={(event: React.MouseEvent<SVGSVGElement>) => {
            this.setPopoverAnchor(index, event);
            this.setCouponId(couponId);
            this.setCouponIndex(index);
          }}
        />
        <CustomPopover
          data-test-id="edit-popover"
          open={Boolean(this.state.popoverAnchors[index])}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: this.getLanguageBasedStyle(isArabic, 'right', 'left'),

          }}
          transformOrigin={{
            vertical: -12,
            horizontal: this.getLanguageBasedStyle(isArabic, 'right', 'left'),
          }}
          anchorEl={this.state.popoverAnchors[index]}
          onClick={() => this.setPopoverAnchor(index)}
          PaperProps={{
            style: {
              height: 82,
              width: "192px",
              boxShadow: "none",
              borderRadius: "8px",
              borderColor: "#CBD5E1",
              marginTop: "24px",
              marginLeft: "-8px",
            },
          }}
        >
          <div
            style={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              textAlign: this.getLanguageBasedStyle(isArabic, 'right', 'left'),
              direction: this.getLanguageBasedStyle(isArabic, 'rtl', 'ltr')
            }}
          >
            <Button
              data-test-id='update-btn'
              className="popoverButton"
              style={{
                padding: "9px 20px 9px 20px",
                textTransform: "none",
                fontWeight: 400,
                height: "36px",
              }}
              onClick={() => {
                this.setCouponFormModal(true);
                this.setIsUpdateMode(true);
              }}
            >
              <img src={editLicense} />
              <Typography style={{ marginLeft: "15px", marginRight: "15px" }}>{t['create-coupon-spa-dashboard--modal-edit']}</Typography>
            </Button>
            <Button
              data-test-id='delete-btn'
              className="popoverButton"
              style={{
                padding: "9px 20px",
                height: "36px",
                textTransform: "none",
                fontWeight: 400,
              }}
              onClick={() => this.deleteCoupons()}
            >
              <img src={deleteLicence} />
              <Typography
                style={{
                  color: "#DC2626",
                  fontFamily: "Ubuntu",
                  marginLeft: "15px", marginRight: "15px"
                }}
              >
                {t['create-coupon-spa-dashboard-modal-delete']}
              </Typography>
            </Button>
          </div>
        </CustomPopover>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const customStyles = {
      background: `linear-gradient(
                   rgba(0, 0, 0, 0.2), 
                   rgba(0, 0, 0, 0.5)
                   ), url(${spaSettingsCover}) center bottom 30% / 100% auto no-repeat`,
    };

    const tableTitles = this.getTranslatedArray([
      'create-coupon-spa-dashboard-coupon-name',
      'create-coupon-spa-dashboard-coupon-code',
      'create-coupon-spa-dashboard-discount-value',
      'create-coupon-spa-dashboard-validity',
      'create-coupon-spa-dashboard-option'
    ]);

    const discountTypes = this.getTranslatedArray([
      'create-coupon-spa-dashboard--discount-type-options-percentage',
      'create-coupon-spa-dashboard--discount-type-options-fixed-amount'
    ]);

    const usageOptions = this.getTranslatedArray([
      'create-coupon-spa-dashboard--usage-type-options-single-use',
      'create-coupon-spa-dashboard--usage-type-options-multi-use'
    ]);

    const multiUse = usageOptions[1];

    const { t, dir, isArabic } = getTranslationConfig();

    return (
      //Merge Engine DefaultContainer
      <Box bgcolor="#F1F5F9">
        <BookingWrapper
          navigation={this.props.navigation}
          forceUpdate={this.forceUpdate.bind(this)}
          heading={t['create-coupon-spa-dashboard-title-heading']}
          breadcrumbs={[t['create-coupon-spa-dashboard-home'], t['create-coupon-spa-dashboard-profile'], t['create-coupon-spa-dashboard-create-coupon']]}
          navigationMap={{
            'Home': 'LandingPage',
            'Spa Profile': 'SpaUserProfile',
            'Create Coupons': '',
          }}
          customStyles={customStyles}
          context={this}
        >
          <TypographyView dir={dir}>
            <Box
              marginTop={4}
              data-testid="toggle-button-box"
              position='relative'
            >
              <Box
                px={4}
                py={2}
                style={{
                  background: '#FFFFFF',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '32px',
                }}
              >
                <TextField
                  data-test-id='coupon-search'
                  placeholder={t['create-coupon-spa-dashboard-search']}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img width={24} height={24} src={imgI14750489301945250HTpe556x7cBoLvN2ZuRRme} alt="input-search" />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  style={{
                    ...styles.inputSearch,
                    borderRadius: '50px',
                    height: '44px',
                    boxSizing: 'border-box',
                    padding: '7px'
                  }}
                  onChange={this.setSearchString}
                />
                <ButtonComponent>
                  <Button
                    data-test-id='create-btn'
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setCouponFormModal(true);
                      this.setIsUpdateMode(false);
                    }}
                    className="regButton"
                    style={{
                      height: '44px',
                    }}
                  >
                    {t['create-coupon-spa-dashboard-create-new']}
                  </Button>
                </ButtonComponent>
              </Box>
              {this.state.loading &&
                <Box
                  minHeight='100vh'
                  display='flex'
                  justifyContent='center'
                >
                  <CircularProgress
                    style={{
                      color: '#398378',
                      marginTop: '15vh'
                    }}
                  />
                </Box>}
              {this.state.coupons.length ?
                // Coupons table
                <Box minHeight='707px'>
                  <Box display='flex'>
                    {tableTitles.map((title) =>
                      <Box
                        key={title}
                        width='20%'
                        p={2}
                        color='#334155'
                        bgcolor='#D9EEEB'
                        style={styles.elipsisOverflow as React.CSSProperties}
                      >
                        <Typography
                          style={{
                            fontWeight: 700
                          }}
                        >
                          {title.toUpperCase()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {this.state.pageCoupons.map((coupon: Coupon, index) => {
                    const resIndex = index + ((this.state.currentPage - 1) * this.couponsCount);
                    const menuComponent = this.menuComponent(resIndex, coupon.id);

                    const {
                      title,
                      code,
                      discount,
                      discount_type: discountType,
                      valid_to: validTo,
                      valid_from: validFrom
                    } = coupon.attributes;

                    const discountString = this.getDiscountString(discount, discountType, t);
                    const validity = `${this.getDateString(validFrom, isArabic)} - ${this.getDateString(validTo, isArabic)}`;

                    const couponOptions = [
                      title,
                      code,
                      discountString,
                      validity,
                      menuComponent
                    ];

                    return (
                      <Box display='flex' key={coupon.id}>
                        {couponOptions.map((option: string | React.ReactElement, index) =>
                          <Box
                            key={index}
                            px={2}
                            py={3}
                            width='20%'
                            bgcolor='#FFFFFF'
                            color='#334155'

                          >
                            {option}
                          </Box>)}
                      </Box>)
                  })}
                </Box>
                :
                <Box
                  style={{
                    width: 'fit-content',
                    margin: 'auto'
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '30px',
                      textAlign: 'center',
                      color: '#000000',
                      fontWeight: 700
                    }}
                  >
                    {t['create-coupon-spa-dashboard-no-coupon']}
                  </Typography>
                  <img
                    src={noCoupons}
                    alt="no coupons"
                    style={{
                      maxHeight: '400px'
                    }}
                  />
                </Box>}
            </Box>

            {/* Pagination */}
            {Boolean(this.state.lastPage) && (
              <Box
                display='flex'
                alignItems='center'
                gridGap={12}
                mt={3}
                margin='auto'
                width='fit-content'
                sx={{
                  flexDirection: this.getLanguageBasedStyle(isArabic, 'row-reverse', 'row'),
                }}
              >
                <Box
                  data-test-id='pagination-back-btn'
                  onClick={this.goBack}
                  {...styles.arrowWrapper}
                  border={`1px solid ${this.getGoBackColor()}`}
                >
                  <ArrowBackIosIcon
                    style={{
                      width: '15px',
                      marginLeft: '6px',
                      color: this.getGoBackColor()
                    }} />
                </Box>
                <Box fontSize='18px' color='#64748B'>
                  {this.state.currentPage} of {this.state.lastPage}
                </Box>
                <Box
                  data-test-id='pagination-next-btn'
                  onClick={this.goForward}
                  {...styles.arrowWrapper}
                  border={`1px solid ${this.getGoForwardColor()}`}
                >
                  <ArrowForwardIosIcon
                    style={{
                      width: '15px',
                      color: this.getGoForwardColor()
                    }} />
                </Box>
              </Box>
            )}
          </TypographyView>

        </BookingWrapper>
        <ModalWrapperComponent
          data-test-id='coupon-form-modal'
          isOpen={this.state.showCouponFormModal}
          modalHeading={t['create-coupon-spa-dashboard-create-new']}
          handleClose={() => {
            this.setCouponFormModal(false);
            this.firstFormLoadRef.current = false;
          }}
          width='50%'
          styles={{
            maxHeight: '95vh',
            minWidth: '60%'
          }}
        >
          <Formik
            initialValues={initCouponFormValues}
            validate={(values) => {
              try {
                validateYupSchema(values,
                  Yup.object().shape({
                    couponName: Yup.string().required(t['create-coupon-spa-dashboard--error-coupon-name']),
                    couponCode: Yup.string()
                      .required(t['create-coupon-spa-dashboard--error-coupon-code-empty'])
                      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/, {
                        message: t['create-coupon-spa-dashboard--error-coupon-code-invalid'],
                        excludeEmptyString: true
                      }),
                    discountType: Yup.string().required(t['create-coupon-spa-dashboard--error-discount-type-empty']),
                    percentage: Yup.string().required(t['create-coupon-spa-dashboard--error-discount-empty']),
                    validFrom: Yup.string().required(t['create-coupon-spa-dashboard--error-validity-dates-empty']),
                    service: Yup.object().shape({ name: Yup.string().required(t['create-coupon-spa-dashboard--error-service-name-empty']) }),
                    usageType: Yup.string().required(t['create-coupon-spa-dashboard--error-usage-type-empty']),
                    ...(values.usageType === multiUse && { usageLimit: Yup.string().nullable().required(t['create-coupon-spa-dashboard--error-usage-limit-empty']) })
                  })
                  , true, values);
              } catch (err) {
                return yupToFormErrors(err); //for rendering validation errors
              }
            }}
            onSubmit={(values) => {
              this.callSaveUpdateCoupons(values);

              this.setCalendarDays([]);
              // Call create or update API
            }}
          >
            {({
              handleChange,
              errors,
              handleBlur,
              setFieldValue,
              values,
              submitForm,
              touched,
              resetForm
            }) => {
              const couponNameError = this.getErrorText(touched.couponName, errors.couponName);
              const couponCodeError = this.getErrorText(touched.couponCode, errors.couponCode);
              const discountTypeError = this.getErrorText(touched.discountType, errors.discountType);
              const discountError = this.getErrorText(touched.percentage, errors.percentage);
              const validityError = this.getErrorText(touched.validFrom, errors.validFrom);
              const serviceError = this.getErrorText(touched.service, errors.service?.name);
              const usageTypeError = this.getErrorText(touched.usageType, errors.usageType);
              const usageLimitError = this.getErrorText(touched.usageLimit, errors.usageLimit);

              // Set values if they are empty for update coupon mode
              if (this.isFirstUpdate(values.couponName)) {
                const {
                  title: couponName,
                  code: couponCode,
                  discount: percentage,
                  discount_type: discountTypeInit,
                  valid_from: validFrom,
                  valid_to: validTo,
                  min_cart_value: minPurchase,
                  services,
                  usage_type: usageTypeInit,
                  usage_limit: usageLimit,
                  
                } = this.state.coupons[this.state.couponIndex].attributes;

                const discountType = this.detectUsageType(discountTypeInit);

                const usageType = usageTypeInit[0].toUpperCase() + usageTypeInit.slice(1);

                const resServices = services.map((service) => ({ id: service.id, name: service.name }));
                const service = this.getService(resServices);
                const visibility =   this.state.coupons[this.state.couponIndex].attributes.visibility

                setFieldValue('couponName', couponName);
                setFieldValue('couponCode', couponCode);
                setFieldValue('discountType', discountType);
                setFieldValue('percentage', Number(percentage).toFixed(0));
                setFieldValue('validFrom', validFrom);
                setFieldValue('validTo', validTo);
                setFieldValue('minPurchase', minPurchase);
                setFieldValue('service', service);
                setFieldValue('usageType', usageType);
                setFieldValue('usageLimit', usageLimit);
                setFieldValue('visibility',visibility)

                this.setCalendarDays([[validFrom, validTo]]);
              } else if (this.isFirstCreate(this.firstFormLoadRef)) {
                // Clean form on first load
                this.setCalendarDays([]);
                this.setCouponId(0);
                resetForm();

                this.firstFormLoadRef.current = true;
              }

              return (
                <RegistrationFormView>
                  <Box px={5} pb={2} borderBottom='1px solid #E2E8F0'>
                    <Box >
                      <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                        <InputLabel className="testLabel">{t['create-coupon-spa-dashboard--coupon-name']}</InputLabel>
                        <InputLabel className="testStar">*</InputLabel>
                      </Box>
                      <TextField
                        name='couponName'
                        value={values.couponName}
                        error={Boolean(couponNameError)}
                        fullWidth={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        className="inputeStyle"
                        data-test-id="coupon-name"
                        placeholder={t['create-coupon-spa-dashboard--coupon-name-placeholder']}
                        style={{
                          height: '56px'
                        }}
                      />
                      <Typography className="txtErrorMsg">{couponNameError}</Typography>
                    </Box>
                    <Box >
                      <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                        <InputLabel className="testLabel">{t['create-coupon-spa-dashboard--coupon-code']}</InputLabel>
                        <InputLabel className="testStar">*</InputLabel>
                      </Box>
                      <TextField
                        name='couponCode'
                        value={values.couponCode}
                        error={Boolean(couponCodeError)}
                        fullWidth={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        className="inputeStyle"
                        data-test-id="coupon-code"
                        placeholder={t['create-coupon-spa-dashboard--coupon-code-placeholder']}
                      />
                      <Typography className="txtErrorMsg">{couponCodeError}</Typography>
                    </Box>
                    <Box display='flex' justifyContent='space-between' gridGap={16}>
                      <Box width='50%'>
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">{t['create-coupon-spa-dashboard--discount-type']}</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <StyledSelect
                          name='discountType'
                          data-test-id="discount-type"
                          fullWidth
                          variant="outlined"
                          value={values.discountType}
                          error={Boolean(discountTypeError)}
                          className="input-booking-select"
                          displayEmpty
                          onChange={handleChange}
                          onBlur={handleBlur}
                          renderValue={this.renderInitSelect(values.discountType, t['create-coupon-spa-dashboard--applicable-services-placeholder'])}
                          IconComponent={ExpandMoreIcon}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null,
                          }}

                        >
                          {discountTypes.map((discount) => <MenuItem key={discount} value={discount}
                            style={{
                              textAlign: this.getLanguageBasedStyle(isArabic, 'right', 'left'),
                              direction: this.getLanguageBasedStyle(isArabic, 'rtl', 'ltr'),
                            }}
                          >{discount}</MenuItem>)}
                        </StyledSelect>
                        <Typography className="txtErrorMsg">{discountTypeError}</Typography>
                      </Box>
                      <Box width='50%' >
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">{t['create-coupon-spa-dashboard--discount']}</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <TextField
                          name='percentage'
                          value={values.percentage}
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          className="inputeStyle"
                          data-test-id="percentage"
                          placeholder={t['create-coupon-spa-dashboard--discount-placeholder']}
                          error={Boolean(discountError)}
                        />
                        <Typography className="txtErrorMsg">{discountError}</Typography>
                      </Box>
                    </Box>
                    {/* DatePicker */}
                    <Box position="relative">
                      <Box
                        sx={{
                          display: "flex",
                          paddingTop: "15px",
                          flexDirection: "row",
                        }}
                      >
                        <InputLabel className="testLabel">
                          {t['create-coupon-spa-dashboard--set-validity']}
                        </InputLabel>
                        <span>&nbsp;</span>
                        <InputLabel className="testStar">
                          *
                        </InputLabel>
                      </Box>
                      <Box position="relative">
                        <img
                          src={calendarIcon}
                          alt="calendar"
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            height: 24,
                            width: 24,
                            top: "50%",
                            transform: "translateY(-50%)",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                          onClick={this.calendarRef.current?.openCalendar}
                        />
                        <DatePicker
                          {...(isArabic ? { locale: arabicLocale } : {})}
                          className='basic-page-picker'
                          ref={this.calendarRef}
                          data-test-id='date-picker-validity'
                          placeholder='DD-MM-YYYY '
                          style={{
                            ...this.getErrorBorder(Boolean(validityError)),
                            paddingRight: this.getLanguageBasedStyle(isArabic, '40px', '0'),
                          }}
                          inputClass='input-date-style'
                          format={isArabic ? 'YYYY-MM-DD' : 'DD-MM-YYYY'}
                          range
                          multiple
                          buttons
                          monthYearSeparator=' '
                          value={this.state.calendarDays}
                          weekStartDayIndex={1}
                          showOtherDays
                          fixMainPosition
                          calendarPosition='bottom-end'
                          offsetX={120}
                          minDate={new Date().setHours(0, 0, 0, 0)}
                          onChange={(dates: any) => {
                            this.setDates(dates, (dates: string[]) => {
                              setFieldValue('validFrom', dates[0]);
                              setFieldValue('validTo', dates[dates.length - 1]);
                            });
                            this.setCalendarDays(dates);
                          }}
                        >
                          <Box
                            borderTop="1px solid #E2E8F0"
                            p={2}
                          >
                            <ButtonComponent>
                              <Button
                                data-test-id="save-date-btn"
                                variant="contained"
                                color="primary"
                                onClick={this.calendarRef.current?.closeCalendar}
                                className="regButton"
                                style={{
                                  ...styles.formButton,
                                  width: "100%",
                                }}
                              >
                                {t['create-coupon-spa-dashboard--save']}
                              </Button>
                            </ButtonComponent>
                          </Box>
                        </DatePicker>
                      </Box>
                      <Typography className="txtErrorMsg">{validityError}</Typography>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                        <InputLabel className="testLabel">{t['create-coupon-spa-dashboard--applicable-services']}</InputLabel>
                        <InputLabel className="testStar">*</InputLabel>
                      </Box>
                      <StyledAutocomplete
                        data-test-id="service-type-input"
                        className='inputeStyle'
                        fullWidth
                        options={this.state.cataloguesList}
                        getOptionLabel={(option: any) => option.name}
                        getOptionSelected={(option: any, value: any) => option.id === value.id}
                        autoComplete
                        autoHighlight
                        disableClearable
                        disablePortal
                        value={values.service}
                        onChange={(_, newValue) => setFieldValue('service', newValue)}
                        onBlur={handleBlur('service')}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            placeholder={t['create-coupon-spa-dashboard--applicable-services-placeholder']}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              error: Boolean(serviceError),
                              style: {
                                ...this.getAutocompleteStyle(Boolean(serviceError)),
                                paddingRight: this.getLanguageBasedStyle(isArabic, '40px', '0'),
                              },
                            }}
                          />
                        )}
                      />
                      <Typography className="txtErrorMsg">{serviceError}</Typography>
                    </Box>
                    <Box>
                      <Box>
                        <InputLabel className="testLabel">{t['create-coupon-spa-dashboard--minimum-purchase-amount']}</InputLabel>
                      </Box>
                      <TextField
                        name='minPurchase'
                        type='number'
                        value={values.minPurchase}
                        fullWidth={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        className="inputeStyle"
                        data-test-id="price-input"
                        placeholder={t['create-coupon-spa-dashboard--minimum-purchase-amount-placeholder']}
                        InputProps={{
                          style: { borderRadius: '8px' },
                          startAdornment: (
                            <InputAdornment position="start">
                              <p style={{ color: '#94A3B8' }}>{this.currency}</p>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                        <InputLabel className="testLabel">{t['create-coupon-spa-dashboard--usage-type']}</InputLabel>
                        <InputLabel className="testStar">*</InputLabel>
                      </Box>
                      <StyledSelect
                        name='usageType'
                        data-test-id="usage-type"
                        fullWidth
                        variant="outlined"
                        value={values.usageType}
                        className="input-booking-select"
                        displayEmpty
                        error={Boolean(usageTypeError)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        renderValue={this.renderInitSelect(values.usageType, t['create-coupon-spa-dashboard--usage-type-placeholder'])}
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {usageOptions.map((usageType) => <MenuItem key={usageType} value={usageType}
                          style={{
                            textAlign: this.getLanguageBasedStyle(isArabic, 'right', 'left'),
                            direction: this.getLanguageBasedStyle(isArabic, 'rtl', 'ltr'),
                          }}
                        >{usageType}</MenuItem>)}
                      </StyledSelect>
                      <Typography className="txtErrorMsg">{usageTypeError}</Typography>
                    </Box>
                    {values.usageType === multiUse &&
                      <Box>
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">{t['create-coupon-spa-dashboard--usage-limit']}</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <TextField
                          name='usageLimit'
                          type='number'
                          value={values.usageLimit}
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          className="inputeStyle"
                          data-test-id="usage-limit-input"
                          placeholder={t['create-coupon-spa-dashboard--usage-limit-placeholder']}
                          InputProps={{
                            style: { borderRadius: '8px' },
                          }}
                        />
                        <Typography className="txtErrorMsg">{usageLimitError}</Typography>
                      </Box>}
                  
                


                    <Box style={{ display: "flex", gap: 12, alignItems: "center", marginTop: "15px" }}>

                      {this.state.avatarImage &&
                        <Box
                          style={{
                            padding: '8px',
                            gap: '16px',
                            borderRadius: '8px',
                            background: '#FFFFFF',
                            ...styles.lightBorder,
                            boxSizing: "content-box",
                          }}
                        >
                          <Box style={{ display: "flex", gap: 12, alignItems: "center" }}>
                            <Box
                              style={{
                                boxSizing: "border-box",
                                width: "100%",
                                height: "100%",
                                background: "#E8F6F4",

                              }}
                            >
                              <img src={this.state.avatarImage} style={{ width: "80px", height: "80px" }} />
                            </Box>
                          </Box>
                        </Box>
                      }
                      <Box>
                        <Button
                          data-test-id="add-carousel-img"
                          component="label"
                          variant="text"
                          style={{
                            color: "#FFFFFF",
                            fontFamily: "Ubuntu",
                            fontSize: 16,
                            fontWeight: 700,
                            padding: 0,
                            marginRight: "16px",
                          }}
                        >
                          <img
                            src={addIcon}
                            style={{ marginRight: 8 }}
                          />
                          <Typography
                            style={{
                              fontFamily: "Ubuntu",
                              fontSize: 16,
                              fontWeight: 700,
                              color: "#398378",
                            }}
                          >
                            Add  Coupen Image
                          </Typography>
                          <input
                            accept="image/*"
                            id="inputinuserblock"
                            data-test-id="add-carousel-image-icon1"
                            className="imgContainer"
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => this.handleProfilePicUpload(event)}
                            type={"file"}
                            style={{
                              width: 0,
                              visibility: "hidden",
                            }}
                          />
                        </Button>
                      </Box>
                    </Box>
                    <Box style={{ marginTop: "15px" }}>

                      <Checkbox
                        name="visibility"
                        onChange={handleChange}
                        checked={values.visibility}
                        data-test-id="checkbox-save-item"
                        className="sametimeAllDay"
                      />
                      <span style={{ fontSize: '14px', color: '#334155', fontWeight: 700 }}>{t['coupen-visiblity']}</span>
                    </Box>
                  </Box>
                  <Box
                    p={3}
                    display='flex'
                    justifyContent='space-between'
                  >

                    <Button
                      data-test-id='dobe-btn'
                      variant="contained"
                      type='submit'
                      style={{
                        marginLeft: 'auto',
                        minWidth: '120px',
                        minHeight: '56px',
                        padding: '6px 16px',
                        backgroundColor: '#398378',
                        color: '#FFFFFF',
                        textTransform: 'none'
                      }}
                      onClick={submitForm}
                    >
                      {this.showBtnWithLoading()}
                    </Button>
                  </Box>
                </RegistrationFormView>
              )
            }}
          </Formik>
        </ModalWrapperComponent>
        <PlanExpiryPrompt context={this} userAction={this.state.userAction} />
      </Box >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledSelect = styled(Select)(() => {
  const { isArabic } = getTranslationConfig();

  return {
    '& .MuiSelect-icon': {
      right: isArabic ? 'unset' : 0,
      left: isArabic ? 0 : 'unset',
      marginLeft: '16px',
    },
    '& .imgContainer': {
      display: 'flex',
      flexDirection: "column",
      justifyContent: "center",
      gap: 12,
      alignItems: "center",
      height: "156px",
      width: "156px",
      background: "var(--Primary, #398378)",
      borderRadius: "50%",
      position: "absolute",
      top: "-50px",
      border: "4px solid #fff",
    },
    '& .profileimg': {
      position: 'absolute',
      width: "100%",
      height: "100%",
      borderRadius: "50%"
    },
  };
});

const StyledAutocomplete = styled(Autocomplete)(() => {
  const { isArabic } = getTranslationConfig();

  return {
    '& .MuiAutocomplete-endAdornment': {
      right: isArabic ? 'unset' : 0,
      left: isArabic ? 0 : 'unset',
    }
  };
});
// Customizable Area End
