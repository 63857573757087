import React from "react";

import {
  Box,
  Button,
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles, TypographyView } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { getTranslationConfig } from "../../../components/src/helpers";

// Customizable Area End

import SubscriptionbillingController, { Props } from "./SubscriptionbillingController";
import { imgAdvancedPlan, imgBasicPlan } from './assets';

export default class Subscriptionbilling extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { t, dir } = getTranslationConfig();
    const { name, type } = this.state.congratsData;
    const isUpdateJourney = this.state.paymentModalUpdateMode;
    const isAdvanced = type === 'Advanced';
    const imageSrc = isAdvanced ? imgAdvancedPlan : imgBasicPlan;
    const profilePrefix = isUpdateJourney ? t['subscription-billing-congrats-text-goto'] : t['subscription-billing-congrats-button-setup']
    const resultDescription = isAdvanced ? t['subscription-billing-congrats-welcome-message'] : t['subscription-billing-congrats-enjoy-journey']

    return (
      <TypographyView>
        <Box style={styles.SafeAreaView} position='relative' className='landing-venue-block' height='auto'>
          <AppHeader context={this} forceUpdate={this.forceUpdate.bind(this)} showProfileIcon={false}  />
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            width='100%'
            boxSizing='border-box'
            py={6}
            px='140px'
          >
            <Box
              maxWidth={500}
              textAlign='center'
              position='relative'
              dir={dir}
              mb={8}
            >
              <img src={imageSrc} alt='subscription plan' />
              <Typography style={{ fontWeight: 700, margin: '48px 0 16px' }}>
                {`${t['subscription-billing-payment-description']} ${name} ${t['subscription-billing-congrats-plan'] || ''} `}
              </Typography>
              <Typography>
                {`${t['subscription-billing-congrats-activated-plan']} ${type} ${t['subscription-billing-congrats-activated-subscription-plan']}. ${resultDescription}`}
                &#x1F389;
              </Typography>
            </Box>
            {/* Buttons */}
            <Box style={{
              width: '100%',
              marginTop: '20px',
              marginBottom: '20px',
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}>
              <Button
                data-test-id='login-button'
                type='submit'
                variant="contained"
                color="primary"
                className="agreeButton"
                style={{
                  width: 'auto',
                  fontSize: '16px',
                  padding: '8px 16px',
                  height: 'auto',
                  marginRight: '10px'
                }}
                startIcon={<ChevronLeftRoundedIcon style={{ ...styles.shevron, color: '#ffffff' }} />}
                onClick={this.gotoSubscriptionPlans}
              >
                {t['subscription-billing-congrats-button-plans']}
              </Button>
              <Button
                data-test-id='nav-button'
                type='button'
                variant="contained"
                color="primary"
                className="agreeButton"
                style={{
                  width: 'auto',
                  fontSize: '16px',
                  padding: '8px 16px',
                  height: 'auto',
                  border: 'none',
                  color: '#398378',
                  background: 'none',
                  boxShadow: 'none'
                }}
                endIcon={<ChevronRightRoundedIcon style={{ ...styles.shevron, color: '#398378' }} />}
                onClick={() => isUpdateJourney ? this.openSpaOwnerProfilePage() : this.gotoSettings()}
              >
                {profilePrefix} {t['subscription-billing-congrats-button-profile']}
              </Button>
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
