import React from "react";
import { Box, Typography, ThemeProvider, Container } from "@material-ui/core";
import { RecoveryPasswordView, TypographyView, styles, theme } from "../PasswordRecovery.web";
import { headerLogoImg } from "../assets";

export const PasswordRecoveryLayout = ({ children, t }: { children: React.ReactNode ,t:Record<string, string>}) => (
        <ThemeProvider theme={theme}>
            <Container style={styles.paperContainer}>
                <Box className="passwordRecoveryView" sx={{
                    display: "flex", flexDirection: "row",
                    justifyContent: "space-between", alignItems: "center", mx: 2, px: 10
                }}>
                <LeftSideContent t={t} />
                    <RecoveryPasswordView sx={{ mt: 5 }}>
                        <FormHeader />
                        <Box className="mainView">
                            {children}
                        </Box>
                    </RecoveryPasswordView>
                </Box>
            </Container>
        </ThemeProvider>
    );

const LeftSideContent = ({ t }: { t: Record<string, string> }) => (
    <Box width="45%">
        <TypographyView>
            <Typography className="heading">{t['welcome']}</Typography>
            <Typography className="description">{t["leftDec"]}</Typography>
        </TypographyView>
    </Box>
);

export const FormHeader = () => (
    <Box className="formHeader">
        <img src={`${headerLogoImg}`} className="logo" alt="Logo" />
    </Box>
);